// Generated by Framer (b0f2619)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFonts, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Icon as Feather } from "https://framerusercontent.com/modules/f0DboytQenYh21kfme7W/zb1zVBMZJKgPMiedOi0y/Feather.js";
const FeatherFonts = getFonts(Feather);

const cycleOrder = ["duZ6gPBoa", "exOi5OYb_"];

const serializationHash = "framer-H1uQi"

const variantClassNames = {duZ6gPBoa: "framer-v-z1iycs", exOi5OYb_: "framer-v-5l4udd"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {Close: "exOi5OYb_", Open: "duZ6gPBoa"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "duZ6gPBoa"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "duZ6gPBoa", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Transition value={transition}><motion.div {...restProps} animate={variants} className={cx(serializationHash, ...sharedStyleClassNames, "framer-z1iycs", className, classNames)} data-framer-name={"Open"} initial={variant} layoutDependency={layoutDependency} layoutId={"duZ6gPBoa"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({exOi5OYb_: {"data-framer-name": "Close"}}, baseVariant, gestureVariant)}><motion.div className={"framer-xzylap-container"} layoutDependency={layoutDependency} layoutId={"Jev1POKO4-container"} style={{rotate: 0}} variants={{exOi5OYb_: {rotate: 360}}}><Feather color={"var(--token-1f2d55b1-7509-480d-9086-134655dcd9c7, rgb(17, 17, 17)) /* {\"name\":\"Neutral / 07\"} */"} height={"100%"} iconSearch={"Home"} iconSelection={"plus"} id={"Jev1POKO4"} layoutId={"Jev1POKO4"} mirrored={false} selectByList style={{height: "100%", width: "100%"}} width={"100%"} {...addPropertyOverrides({exOi5OYb_: {iconSelection: "minus"}}, baseVariant, gestureVariant)}/></motion.div></motion.div></Transition>
</LayoutGroup>)

});

const css = [".framer-H1uQi[data-border=\"true\"]::after, .framer-H1uQi [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-H1uQi.framer-30nnst, .framer-H1uQi .framer-30nnst { display: block; }", ".framer-H1uQi.framer-z1iycs { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: 17px; justify-content: center; overflow: visible; padding: 0px 0px 0px 0px; position: relative; width: 16px; }", ".framer-H1uQi .framer-xzylap-container { flex: none; height: 16px; position: relative; width: 100%; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-H1uQi.framer-z1iycs { gap: 0px; } .framer-H1uQi.framer-z1iycs > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-H1uQi.framer-z1iycs > :first-child { margin-top: 0px; } .framer-H1uQi.framer-z1iycs > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 17
 * @framerIntrinsicWidth 16
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"exOi5OYb_":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const FramerI7HC_JnK9: React.ComponentType<Props> = withCSS(Component, css, "framer-H1uQi") as typeof Component;
export default FramerI7HC_JnK9;

FramerI7HC_JnK9.displayName = "FAQ Icon";

FramerI7HC_JnK9.defaultProps = {height: 17, width: 16};

addPropertyControls(FramerI7HC_JnK9, {variant: {options: ["duZ6gPBoa", "exOi5OYb_"], optionTitles: ["Open", "Close"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerI7HC_JnK9, [...FeatherFonts])